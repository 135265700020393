// routes
import { PATH_DASHBOARD } from "routes/paths";
// components
// import Label from '../../../components/Label';
import SvgIconStyle from "components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  moneybag: getIcon("ic_moneybag"),
  accounting: getIcon("ic_accounting"),
  staff: getIcon("ic_staff"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      { title: "app", path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    ],
  },

  {
    subheader: "Content",
    items: [
      {
        title: "Products",
        path: PATH_DASHBOARD.products,
        icon: ICONS.ecommerce,
      },
      {
        title: "Categories",
        path: PATH_DASHBOARD.categories,
        icon: ICONS.calendar,
      },
      {
        title: "Types",
        path: PATH_DASHBOARD.types,
        icon: ICONS.cart,
      },
      {
        title: "Tags",
        path: PATH_DASHBOARD.tags,
        icon: ICONS.booking,
      },
    ],
  },

  {
    subheader: "People",
    items: [
      {
        title: "Users",
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
      },
      {
        title: "Vendors",
        path: PATH_DASHBOARD.vendors.root,
        icon: ICONS.staff,
      },
    ],
  },
];

export default navConfig;
