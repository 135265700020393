import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "layouts/dashboard";
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
// guards
import GuestGuard from "guards/GuestGuard";
import AuthGuard from "guards/AuthGuard";
import RoleBasedGuard from "guards/RoleBasedGuard";

// components
import LoadingScreen from "components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/app")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify/:email", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "app",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/app/dashboard" replace />, index: true },
        { path: "dashboard", element: <Dashboard /> },

        {
          path: "products",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard
                  accessibleRoles={["admin", "systemadmin", "stock"]}
                >
                  <ProductsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: "update/:productId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin", "systemadmin"]}>
                  <ProductsUpdate />
                </RoleBasedGuard>
              ),
            },
            {
              path: "view/:productId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin", "systemadmin"]}>
                  <ProductsView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "categories",
          element: (
            <RoleBasedGuard accessibleRoles={["admin", "systemadmin", "stock"]}>
              <CategoriesList />
            </RoleBasedGuard>
          ),
        },
        {
          path: "types",
          element: (
            <RoleBasedGuard accessibleRoles={["admin", "systemadmin", "stock"]}>
              <TypesList />
            </RoleBasedGuard>
          ),
        },

        {
          path: "tags",
          element: (
            <RoleBasedGuard accessibleRoles={["admin", "systemadmin", "stock"]}>
              <TagsList />
            </RoleBasedGuard>
          ),
        },
        {
          path: "vendors",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard
                  accessibleRoles={["admin", "systemadmin", "stock"]}
                >
                  <VendorsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: "details/:vendorId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin", "systemadmin"]}>
                  <VendorsDetails />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: "users",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard accessibleRoles={["systemadmin"]}>
                  <UsersList />
                </RoleBasedGuard>
              ),
            },
            { path: "profile", element: <UserProfile /> },
          ],
        },

        { path: "404", element: <NotFound /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [{ path: "*", element: <Navigate to="/app/404" replace /> }],
    },
    {
      path: "/",
      element: <Navigate to="/app/dashboard" replace />,
      index: true,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("pages/auth/Login")));
const ResetPassword = Loadable(lazy(() => import("pages/auth/ResetPassword")));
const VerifyCode = Loadable(lazy(() => import("pages/auth/VerifyCode")));

// Dashboard
const Dashboard = Loadable(lazy(() => import("pages/Dashboard")));
const NotFound = Loadable(lazy(() => import("pages/Page404")));

// Products
const ProductsList = Loadable(lazy(() => import("pages/products/List")));
const ProductsUpdate = Loadable(lazy(() => import("pages/products/Edit.js")));
const ProductsView = Loadable(lazy(() => import("pages/products/View.js")));

// Categories
const CategoriesList = Loadable(lazy(() => import("pages/categories/List")));

// Types
const TypesList = Loadable(lazy(() => import("pages/itemtypes/List")));

// Tags
const TagsList = Loadable(lazy(() => import("pages/tags/List")));

// Vendors
const VendorsList = Loadable(lazy(() => import("pages/vendors/List")));
const VendorsDetails = Loadable(lazy(() => import("pages/vendors/Details")));

// Users
const UsersList = Loadable(lazy(() => import("pages/users/list")));
const UserProfile = Loadable(lazy(() => import("pages/users/profile.js")));
