import { createContext } from 'react';

// theme
import palette from 'theme/palette';


// ----------------------------------------------------------------------

const initialState = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onResetSetting: () => {},
  setColor:   {
    name: 'default',
    ...palette.light.primary,
  },
  colorOption: [],
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------



export {  SettingsContext };
